import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import getBrowserHistory from 'yoda-core-components/lib/navigation/history/getBrowserHistory';
import classNames from 'classnames/bind';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
/* Core imports */
import cookie from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import makeQuery from 'yoda-core-components/lib/navigation/query/makeQuery';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
/* Account imports */
import Layout from 'yoda-site-components/lib/components/CAMNav/WithLayout';
import Routing from 'yoda-account-components/lib/helpers/Routing/Routing';
import CAMErrorComponent from 'yoda-account-components/lib/components/CAMErrorComponent/CAMErrorComponent';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import AlertModal from '../AlertModal';
/* Constants */
import {
    internalError,
    lilErrorCode,
    navigateToRewardsHome,
    scrollToIdBenefits,
    sessionError,
} from '../../../../../common/Constants';
import { emailRegExp } from '../../../../../common/regex';

/* Partials */
import WelcomeBanner from '../../../../../components/WelcomeBanner/WelcomeBanner';
import UserDetails from '../../../../../components/UserDetails/UserDetails';
import SkeletonBox from '../../../../../components/SkeletonBox/SkeletonBox';
import RewardsPendingComponent from '../RewardsPendingComponent/RewardsPendingComponent';
import RewardsPreferencesWrapper from '../../RewardsPreferencesWrapper';
import RewardsSpecialOffersScreen from '../RewardsSpecialOffersScreen/RewardsSpecialOffersScreenConnectLazy';
import RewardsLinkCreditCardScreen from '../RewardsLinkCreditCardScreen/RewardsLinkCreditCardScreenConnectLazy';
import RewardsSettingsScreen from '../RewardsSettingsScreen/RewardsSettingsScreenConnectLazy';
import RewardsHistoryScreen from '../RewardsHistoryScreen/RewardsHistoryScreenConnectLazy';

/* Styling */
import * as styles from './RewardsLegacy.css';

const cx = classNames.bind(styles);

class RewardsDashboardPageLegacy extends Component {
    static defaultProps = {
        location: {
            query: {
                desktop: false,
            },
            pathname: '',
        },
        rewardsDetails: {
            data: '',
            certificates: { data: [] },
        },
        keepOverlay: true,
        launchNLearnDetails: {},
        messagesTexts: {},
        featureFlags: {},
        firstName: '',
        isNative: false,
    };

    static propTypes = {
        rewardsDetails: PropTypes.object,
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        keepOverlay: PropTypes.bool,
        accountDetailsActions: PropTypes.objectOf(PropTypes.func).isRequired,
        rewardsSettingsDetails: PropTypes.objectOf.isRequired,
        messagesTexts: PropTypes.object,
        featureFlags: PropTypes.object,
        location: PropTypes.object,
        preferences: PropTypes.objectOf.isRequired,
        sendAuthEmail: PropTypes.objectOf(PropTypes.func).isRequired,
        launchNLearnDetails: PropTypes.object,
        accountDetails: PropTypes.objectOf.isRequired,
        userState: PropTypes.string.isRequired,
        firstName: PropTypes.string,
        redirectAction: PropTypes.objectOf(PropTypes.func).isRequired,
        isNative: PropTypes.bool,
    };

    constructor(props) {
        super();
        const query = makeQuery(get(props, 'location.search'));
        const showMissingPoints = get(query, 'missingPoints', false);
        this.state = {
            mounted: false,
            cmvid: null,
            errorMsg: false,
            messageState: '',

            /* Scroll to Disclosure ref node */
            scrollTo: null,
            showMissingPoints,
            isRewardsProfileFailure: false,
            rewardsStatus: TokenProvider.get('DP_REWARDS_STATUS') || '',
            email: TokenProvider.get('DP_USER_EMAIL') || '',
            showRewardsError: false,
        };
    }

    /* eslint-disable camelcase */
    UNSAFE_componentWillMount() {
        const {
            location: { pathname = '' },
        } = this.props;
        // All redirects goes here
        if (/\/settings[/]?$/.test(pathname)) {
            const profileUrl = get(
                this.props.preferences,
                'navigateToProfileUpdate',
                '/account/dashboard/personal/info'
            );
            if (__SERVER__) {
                // server redirects
                const { redirectAction } = this.props;
                redirectAction.setRedirectUrl({ url: profileUrl });
            } else {
                // client redirects
                window.location.assign(profileUrl);
            }
        }
    }

    componentDidMount() {
        this.retrieveProfileAndRewards();
        this.setCustomerIdentificationCookie();
        this.setState({
            // eslint-disable-line react/no-did-mount-set-state
            mounted: true,
        });
    }

    /* eslint-disable camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { rewardsDetails, launchNLearnDetails, accountDetails } = nextProps;
        const {
            launchNLearnDetails: prevLnLDetails,
            messagesTexts,
            preferences: { serviceError },
        } = this.props;
        /* istanbul ignore if */
        if (
            nextProps.userState &&
            nextProps.userState !== this.props.userState &&
            nextProps.userState === '0'
        ) {
            getBrowserHistory().push('/rewards');
        }

        const validRewardsDetails = this.ensureValidRewardsDetails(
            rewardsDetails,
            getBrowserHistory()
        );
        const rewardsStatusFromAccountProfile = get(
            accountDetails,
            'profile.rewardsProfile.status',
            ''
        );
        let rewardsStatus = get(rewardsDetails, 'data.status', rewardsStatusFromAccountProfile);
        rewardsStatus = rewardsStatus.toLowerCase();
        this.state.rewardsStatus !== rewardsStatus && this.setState({ rewardsStatus });
        if (rewardsStatus === 'enrolled') {
            this.retrieveMessageState();
        } else if (rewardsStatus === 'loyaltyenrollmentprogress') {
            getBrowserHistory().push('/rewards');
        }

        if (launchNLearnDetails.authEmail) {
            const { authEmail } = launchNLearnDetails;
            if (
                authEmail.isSuccess &&
                get(prevLnLDetails, 'authEmail.isSuccess') !== authEmail.isSuccess
            ) {
                this.setState({
                    isLoading: false,
                    resendMessage: {
                        text: get(
                            messagesTexts,
                            'rewardsDashboard.authEmailSuccessMessage',
                            'Email Sent'
                        ),
                        type: 'success',
                    },
                });
            } else if (!authEmail.isLoading && !authEmail.isSuccess) {
                this.setState({
                    isLoading: false,
                    resendMessage: {
                        text: get(
                            messagesTexts,
                            'rewardsDashboard.authEmailFailureMessage',
                            'Unable to send email at this moment. Please try again.'
                        ),
                        type: 'error',
                    },
                });
            }
        }
        if (
            !validRewardsDetails &&
            !rewardsDetails.isSuccess &&
            (rewardsDetails.status !== 200 || rewardsDetails.status !== 401)
        ) {
            const eplisonDownError = get(rewardsDetails, 'error.errorCode', '');
            const isEplisonDown = eplisonDownError === get(serviceError, 'eplisonDownError');
            if (isEplisonDown) {
                this.setState({ showRewardsError: true, isRewardsProfileFailure: false });
            } else {
                this.setState({ isRewardsProfileFailure: true });
            }
        } else {
            this.setState({ isRewardsProfileFailure: false });
        }
    }

    /**
     * Given a customer identification cookie, adjust the state accordingly.
     */
    setCustomerIdentificationCookie = () => {
        const cmvid = cookie.load('cmvid');

        /* istanbul ignore if */
        if (cmvid) {
            this.setState({ cmvid });
        }
    };

    /* Set the scrollTo node ref to state */
    setRef = (refNode) => {
        this.setState((previousState) => ({
            ...previousState,
            scrollTo: refNode,
        }));
    };

    retrieveMessageState = () => {
        const { location } = this.props;
        const query = makeQuery(location.search);
        const messageState = get(query, 'messageState');
        /* istanbul ignore if */
        if (messageState && this.state.messageState !== messageState) {
            this.setState({ messageState }, () => {
                const oldQuery = query || {};
                const queryOmmited = omit(oldQuery, ['messageState']);
                getBrowserHistory().push({ ...location, queryOmmited });
            });
        }
    };

    /* Scroll to Disclosure method */
    scrollToDisclosure = (ev) => {
        ev.preventDefault();
        this.state.scrollTo.scrollIntoView({ behavior: 'smooth' });
    };

    /**
     * Retrieve the necessary details to properly display the Rewards page.
     * @returns {boolean}
     */
    retrieveProfileAndRewards = () => {
        const {
            accountDetailsActions: { getProfile },
            actions: { getRewards },
            featureFlags: { enableThirdPartyCoupons, enableRewardsWebview = true },
            isNative,
        } = this.props;
        let rewardsStatus = TokenProvider.get('DP_REWARDS_STATUS') || '';
        rewardsStatus = rewardsStatus.toLowerCase();
        let queryParams = window.location.href.split('?')[1];
        queryParams = queryParams ? `?${queryParams}` : '';
        const pathLocation = window.location.pathname;
        /* istanbul ignore next */
        if (
            (isNative && enableRewardsWebview) ||
            Routing.ensureAuthenticatedRoute(`${pathLocation}${queryParams}`)
        ) {
            if (rewardsStatus === 'notenrolled') {
                getBrowserHistory().push('/rewards');
            } else if (rewardsStatus === 'enrolled') {
                getRewards(false, true, enableThirdPartyCoupons);
            } else {
                getProfile({ redirect: true });
            }
        }
    };

    /**
     * Given an invalid rewardsDetails object, redirect the User to a different page.
     * @param {Object} rewardsDetails
     *  @property {Object} data - A collection of details related to a User's Rewards status.
     * @param {Object} getBrowserHistory() - Some library for handling a User's browser.
     */
    ensureValidRewardsDetails = (rewardsDetails) => {
        const { data = {}, status, isLoading, error = {} } = rewardsDetails;

        if (isLoading) return false;

        const { errorCode } = error;

        if (!isEmpty(error)) {
            return false;
        }

        const hasError =
            errorCode === lilErrorCode || errorCode === sessionError || errorCode === internalError;

        const badResponse = status !== undefined && status !== 200 && status !== 401;
        const userState = TokenProvider.get('DP_USER_STATE');
        // check enrolled status only when user state = 1
        const notEnrolled = userState === 1 && data.status !== 'Enrolled';

        /* istanbul ignore if */
        if (notEnrolled || badResponse) {
            getBrowserHistory().push(navigateToRewardsHome);
        }
        /* istanbul ignore if */
        if (hasError) {
            this.setState({ errorMsg: true });
            return false;
        }

        return true;
    };

    analyticsEvent = (navData) => {
        this.props.actions.getRewardsNavPanelData({
            pageName: navData.pageName,
            pageType: 'rewards',
            siteSection: 'rewards',
        });
    };

    sendAuthEmail = () => {
        const payload = {
            email: this.state.email,
        };
        this.props.sendAuthEmail(payload);
        this.setState({ isLoading: true });
    };

    hideLnlSuccessMessage = () => {
        this.setState({ messageState: '' });
        getBrowserHistory().push('/rewards/rewards/dashboard');
    };

    showSuccessAlertForLnL = () => {
        const {
            messagesTexts: { lnlFlowsMessagesMap },
        } = this.props;

        const { messageState } = this.state;

        const successMessage = get(
            lnlFlowsMessagesMap,
            `successMessage${messageState || 'Enroll'}`
        );

        return (
            <AlertModal
                message={successMessage}
                showModal={!!messageState}
                title="Welcome !"
                closeModal={this.hideLnlSuccessMessage}
            />
        );
    };

    render() {
        const {
            keepOverlay,
            rewardsDetails,
            rewardsSettingsDetails,
            messagesTexts,
            preferences: { welcomeMessage },
        } = this.props;

        const {
            //   errorMsg,
            isRewardsProfileFailure,
            showRewardsError,
        } = this.state;

        const { userState, firstName, preferences, isNative, featureFlags } = this.props;
        const { data = {}, isLoading: rewardsDataLoading } = rewardsDetails;
        const isLoading = rewardsSettingsDetails ? rewardsSettingsDetails.isLoading : false;
        const username = TokenProvider.get('DP_FIRST_NAME') || '';
        let tier = data && data.tier ? data.tier.code.toLowerCase() : '';
        if (tier === 'credit') {
            tier = 'Credit';
        } else {
            tier = 'Rewards';
        }
        const loadingAutomationId = 'test-automation-loader-1';
        const isValidEmail = this.state.email.match(emailRegExp);
        const email = isValidEmail ? this.state.email : '';
        const rewardsPendingCardContent = `${get(
            messagesTexts,
            'rewardsDashboard.rewardsPendingCardContent[0]',
            'Please check your email at '
        )}${email}${get(
            messagesTexts,
            'rewardsDashboard.rewardsPendingCardContent[1]',
            ' to verify and activate your JCPenney Rewards.'
        )}`;
        const eplisonDownText = get(welcomeMessage, 'newEplisonDownText', '');
        const hideIsNative = get(featureFlags, 'enableRewardsWebview', true) && isNative;
        return (
            <Layout
                layoutState={{
                    title: userState !== '0' && firstName ? `Hi, ${firstName}!` : 'My Rewards',
                    rewardsErrorObj: {
                        rewardsError: showRewardsError,
                        rewardsErrorText: eplisonDownText,
                    },
                }}
                classes={{
                    panelContentContainer: `${cx('content-container')} ${dt([
                        'min-w-0',
                        'flex',
                        'flex-col',
                        'w-full',
                        'md12',
                    ])}`,
                    layoutHeaderClass: dt(['md12']),
                    root: cx('layout-container'),
                }}
                hideHeader={hideIsNative}
            >
                {rewardsDataLoading && (
                    <Loader automationId={loadingAutomationId} keepOverlay={keepOverlay} />
                )}
                {this.showSuccessAlertForLnL()}
                {isRewardsProfileFailure && !rewardsDataLoading ? (
                    <CAMErrorComponent
                        classes={{ wrapper: cx('errorComponent') }}
                        type="page-level"
                        errorDetails={{
                            message: messagesTexts.launchNLearn.getAccountsFailureMessage,
                        }}
                        onRetry={this.retrieveProfileAndRewards}
                    />
                ) : (
                    <div className={cx('camDashboard')}>
                        {/* Dashboard header. */}
                        <div
                            className={`${cx('welcomeContent')} ${dt([
                                'sm:block',
                                'md:flex',
                                'bg-white',
                                'bg-cover',
                                'w-full',
                                'border-solid',
                                'border-b-4',
                                'border-black',
                                'overflow-hidden',
                                'pb-6',
                                hideIsNative && 'mt-4',
                            ])}`}
                        >
                            <WelcomeBanner
                                rewardsDetails={rewardsDetails}
                                preferences={preferences}
                            />
                            <UserDetails username={username} tier={tier} />
                        </div>
                        {/* Dashboard content. */}
                        {this.state.mounted &&
                            get(this.state, 'rewardsStatus', '').toLowerCase() === 'pending' && (
                                <>
                                    {this.state.isLoading && (
                                        <Loader
                                            automationId={loadingAutomationId}
                                            keepOverlay={keepOverlay}
                                        />
                                    )}
                                    <RewardsPendingComponent
                                        activtyMapRegion="rewards dashboard"
                                        classes={{
                                            wrapper: `${cx('pendingComponent')} ${dt([
                                                'bg-white',
                                                'rounded-lg',
                                            ])}`,
                                            title: `${cx('pendingComponentTitle')} ${dt([
                                                'mt-2',
                                                'mb-6',
                                            ])}`,
                                            description: `${dt(['!mb-6'])}`,
                                            footerText: `${cx('pendingComponentFooter')} ${dt([
                                                'sm:!text-gray-60',
                                            ])}`,
                                        }}
                                        content={{
                                            title: get(
                                                messagesTexts,
                                                'rewardsDashboard.rewardsPendingCardTitle',
                                                'Rewards Status Pending'
                                            ),
                                            message: rewardsPendingCardContent,
                                        }}
                                        onResend={this.sendAuthEmail}
                                        footerText={get(
                                            messagesTexts,
                                            'rewardsDashboard.rewardsPendingCardFooter',
                                            'Having trouble? Call 1-888-JCP-RWDS'
                                        )}
                                        message={this.state.resendMessage}
                                    />
                                </>
                            )}
                        {get(this.state, 'rewardsStatus', '').toLowerCase() !== 'pending' &&
                            !showRewardsError && (
                                <>
                                    {this.state.mounted && data ? (
                                        <div className={dt(['w-full', 'flex', 'flex-row'])}>
                                            {isLoading && (
                                                <Loader
                                                    automationId={loadingAutomationId}
                                                    keepOverlay={keepOverlay}
                                                />
                                            )}

                                            {/* Screens are injected into view here. */}
                                            <div
                                                className={`${cx(
                                                    'sm12',
                                                    'md12',
                                                    'lg12',
                                                    'xl12'
                                                )} ${dt(['!px-0'])}`}
                                            >
                                                <div
                                                    className={`${cx('rewardsRightPanel')} ${dt([
                                                        'bg-white',
                                                        'overflow-hidden',
                                                        'w-full',
                                                        'h-full',
                                                        'sm:py-6',
                                                    ])}`}
                                                >
                                                    {/* { React.cloneElement(this.props.children,
                                                  { scrollToDisclosure: this.scrollToDisclosure }) } */}
                                                    <Switch>
                                                        <Redirect
                                                            exact
                                                            from="/rewards/rewards/dashboard/benefits"
                                                            to={`/rewards?scrollId=${scrollToIdBenefits}`}
                                                        />

                                                        <Route
                                                            exact
                                                            path="/rewards/rewards/dashboard/special-offers"
                                                            component={RewardsSpecialOffersScreen}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/rewards/rewards/dashboard/link-credit-card"
                                                            component={RewardsLinkCreditCardScreen}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/rewards/rewards/dashboard/history"
                                                            component={RewardsHistoryScreen}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/rewards/rewards/dashboard/settings"
                                                            component={RewardsSettingsScreen}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/rewards/rewards/dashboard"
                                                            component={RewardsPreferencesWrapper}
                                                        />
                                                    </Switch>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={cx(
                                                'sm12',
                                                'md12',
                                                'lg8',
                                                'xl8',
                                                'noPadding'
                                            )}
                                        >
                                            <div className={cx('rewardsRightPanel')}>
                                                <SkeletonBox />
                                                <SkeletonBox />
                                                <SkeletonBox />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                    </div>
                )}
            </Layout>
        );
    }
}

export default RewardsDashboardPageLegacy;
